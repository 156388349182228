import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react';

import { AngellistIcon } from '../../../assets/icons/Angellist.icon';
import { CrunchbaseIcon } from '../../../assets/icons/Crunchbase.icon';

export const SocialLinks = ({
  angellistUrl,
  crunchbaseUrl,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
  ...rebassProps
}) => {
  const normalizeUrl = (url) => (url.startsWith('http') ? url : `https://${url}`);

  return (
    <Flex {...rebassProps}>
      {linkedinUrl && (
        <a href={normalizeUrl(linkedinUrl)} target="_blank" rel="noopener noreferrer" data-testid="linkedin">
          <Box as={Linkedin} height="16px" width="16px" mr={2} color="#4a4a4a" strokeWidth="1.5" aria-hidden="true" />
        </a>
      )}
      {angellistUrl && (
        <a href={normalizeUrl(angellistUrl)} target="_blank" rel="noopener noreferrer" data-testid="angelist">
          <Box
            as={AngellistIcon}
            height="16px"
            width="16px"
            mr={2}
            color="#4a4a4a"
            strokeWidth="1.5"
            aria-hidden="true"
          />
        </a>
      )}
      {crunchbaseUrl && (
        <a href={normalizeUrl(crunchbaseUrl)} target="_blank" rel="noopener noreferrer" data-testid="crunchbase">
          <Box
            as={CrunchbaseIcon}
            height="16px"
            width="16px"
            mr={2}
            color="#4a4a4a"
            strokeWidth="1.5"
            aria-hidden="true"
          />
        </a>
      )}
      {twitterUrl && (
        <a href={normalizeUrl(twitterUrl)} target="_blank" rel="noopener noreferrer" data-testid="twitter">
          <Box as={Twitter} height="16px" width="16px" mr={2} color="#4a4a4a" strokeWidth="1.5" aria-hidden="true" />
        </a>
      )}
      {facebookUrl && (
        <a href={normalizeUrl(facebookUrl)} target="_blank" rel="noopener noreferrer" data-testid="facebook">
          <Box as={Facebook} height="16px" width="16px" mr={2} color="#4a4a4a" strokeWidth="1.5" aria-hidden="true" />
        </a>
      )}
      {instagramUrl && (
        <a href={normalizeUrl(instagramUrl)} target="_blank" rel="noopener noreferrer" data-testid="instagram">
          <Box as={Instagram} height="16px" width="16px" mr={2} color="#4a4a4a" strokeWidth="1.5" aria-hidden="true" />
        </a>
      )}
    </Flex>
  );
};

SocialLinks.propTypes = {
  angellistUrl: PropTypes.string,
  crunchbaseUrl: PropTypes.string,
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
};

SocialLinks.defaultProps = {
  angellistUrl: null,
  crunchbaseUrl: null,
  facebookUrl: null,
  instagramUrl: null,
  linkedinUrl: null,
  twitterUrl: null,
};
