import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass/styled-components';

export const InfoItem = ({ title, children, capitalize }) => (
  <Box
    sx={{
      '& > p:first-child': {
        mb: '4px',
        textTransform: 'uppercase',
        color: 'text.subtle',
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '1px',
      },
      '& > p:last-child': {
        textTransform: capitalize ? 'capitalize' : 'none',
        color: 'text.main',
        lineHeight: '17px',
        fontSize: '14px',
      },
    }}
  >
    <Text as="p">{title}</Text>
    <Text as="p">{children}</Text>
  </Box>
);

InfoItem.defaultProps = {
  capitalize: false,
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  capitalize: PropTypes.bool,
};

export default InfoItem;
