import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Box, Card, Button, Text } from 'rebass/styled-components';

import { ChevronLeft } from 'lucide-react';
import NextLink from 'next/link';
import { Logo, Modal } from '@getro/rombo';
import Container from '../../atoms/Container';
import Footer from '../../atoms/Footer';
import CustomFooter from '../../molecules/customFooter';

import Hero from '../../molecules/Hero';
import { CustomHeader } from '../../molecules/customHeader';
import { makeSelectIsInternalNav, makeSelectNavigationHistory } from '../../../redux/selectors/page';
import Navbar from '../../organisms/Navbar';
import { isAuthenticatedSelector, makeSelectShowSignUpModal } from '../../../redux/selectors/user';
import {
  hasAdvancedDesignSelector,
  hasCustomHeroSelector,
  networkLegalSelector,
  numbersInNavSelector,
  organizationsStringSelector,
  talentNetworkSelector,
} from '../../../redux/selectors/network';
import { NetworkSchema } from '../../../schema/network';
import { toggleSignUpModal } from '../../../redux/actions/userActions';
import useAllCompanies from '../../../hooks/useAllCompanies';
import { useTriggerScript } from '../../atoms/Scripts/hooks/useTriggerScript';
import usePrivacyPolicyUrl from '../../../hooks/usePrivacyPolicyUrl';

const DetailsLayout = ({ network, advancedDesign, children }) => {
  const organizationsString = organizationsStringSelector(true, network);
  const hasCustomHero = hasCustomHeroSelector(network);
  const networkLegal = networkLegalSelector(network);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const talentNetwork = talentNetworkSelector(network);
  const numbersInNav = numbersInNavSelector(network);
  const navigationHistory = useSelector(makeSelectNavigationHistory);
  const isInternalNav = useSelector(makeSelectIsInternalNav);
  const hasAdvancedDesign = hasAdvancedDesignSelector(network);
  const showSignUpModal = useSelector(makeSelectShowSignUpModal);
  const organizationString = organizationsStringSelector(false, network);
  const { list: allCompaniesList } = useAllCompanies({ network });
  const { privacyPolicyUrl, isExternal: isExternalPrivacyPolicyUrl } = usePrivacyPolicyUrl(network);

  const dispatch = useDispatch();

  const { name: networkName } = network;

  const parentPage = useMemo(() => {
    if (typeof window !== 'undefined' && window.location?.url?.indexOf('/jobs/') > -1) {
      return `${process.env.NEXT_PUBLIC_BASE_PATH}/jobs`;
    }
    return `${process.env.NEXT_PUBLIC_BASE_PATH}/company`;
  }, []);

  const backElementName = useMemo(() => {
    if (navigationHistory.length === 0) {
      return null;
    }
    const parent = navigationHistory[0];
    if (parent && parent.indexOf('jobs') > -1) {
      return ' to jobs';
    }
    return ` to ${organizationsString}`;
  }, [navigationHistory, organizationsString]);

  const backPath = useMemo(() => {
    if (!navigationHistory[0]) return parentPage;

    if (process.env.NEXT_PUBLIC_BASE_PATH) {
      return navigationHistory[0].replace(process.env.NEXT_PUBLIC_BASE_PATH, '');
    }

    return navigationHistory[0];
  }, [navigationHistory, parentPage]);

  useTriggerScript();
  return (
    <Flex bg="neutral.20" minHeight="100vh" flexDirection="column" alignItems="stretch" id="wlc-main">
      <Box width={1}>
        {hasAdvancedDesign && <CustomHeader advancedDesign={advancedDesign} network={network} />}
        {!hasCustomHero && <Hero network={network} />}
        <Box id="content">
          {isInternalNav && (
            <Card width={[1]} bg="white" sx={{ boxShadow: '0 1px 0 0 #e5e5e5' }}>
              <Flex flexDirection="row" width={[1]} py={3} alignItems="center" mx="auto" sx={{ maxWidth: '1100px' }}>
                {/* anchor to parent */}
                <NextLink shallow passHref href={backPath} legacyBehavior>
                  <Button className="theme_only" variant="getroLink" color="text.main" as="a" fontSize={2}>
                    <Text variant="variants.icon" as={ChevronLeft} fontSize={0} />
                    Back{backElementName}
                  </Button>
                </NextLink>
              </Flex>
            </Card>
          )}
          {!isInternalNav && (
            <Navbar
              network={network}
              isAuthenticated={isAuthenticated}
              talentNetwork={talentNetwork}
              numbersInNav={numbersInNav}
              organizationsString={organizationsString}
              talentNetworkV2={network.hasTalentNetworkV2}
            />
          )}
          <Container pb={5}>{children}</Container>
        </Box>
      </Box>
      <Footer
        hasAdvancedDesign={hasAdvancedDesign}
        networkName={networkName}
        networkLegal={networkLegal}
        isExternalPrivacyPolicyUrl={isExternalPrivacyPolicyUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
      {hasAdvancedDesign && <CustomFooter advancedDesign={advancedDesign} network={network} />}
      <Modal
        p={0}
        data-testid="signin-modal"
        onCancel={() => dispatch(toggleSignUpModal({ network }))}
        isOpen={showSignUpModal}
        variant="large"
      >
        <Flex alignItems="stretch" ml={-3}>
          <Box display={['none', 'block']} p={4} width={[1 / 2]} bg="neutral.50">
            <Flex flexDirection="column">
              <Text fontWeight="medium">
                Sign up to signal that you&rsquo;d be interested in working with a Fantasia {organizationString} to help
                us put the right opportunities at great {organizationsString} on your radar.
              </Text>
              <Text my={3}>How it works</Text>
              <Flex tx="lists" as="ol" flexDirection="column" variant="styleless">
                <Box as="li" mb={3}>
                  <Flex>
                    <Box mr={3}>
                      <Text variant="circle" aria-hidden="true">
                        1
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Tell us about your ideal fit
                      </Text>
                      <Text>
                        Fill out a 5-step profile designed to give us a sense of what type of opportunities you&rsquo;d
                        be open to next.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box as="li" mb={3}>
                  <Flex>
                    <Box mr={3}>
                      <Text aria-hidden="true" variant="circle">
                        2
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Engage with our job board
                      </Text>
                      <Text>
                        Create lists of favorite {organizationsString} and job opportunities on our interactive job
                        board periodically to keep a pulse on priority opportunities.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box as="li">
                  <Flex>
                    <Box mr={3}>
                      <Text aria-hidden="true" variant="circle">
                        3
                      </Text>
                    </Box>
                    <Flex ml={3} flexDirection="column">
                      <Text fontWeight="medium" mb={1}>
                        Get connected with hiring teams
                      </Text>
                      <Text>
                        Allow {organizationsString} in our network to search and discover your profile. If there is
                        mutual interest, skip-the-applicant-line, we&rsquo;ll make the introduction.
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box p={4} width={[1, 1 / 2]}>
            <Flex height="100%" flexDirection="column" textAlign="center" alignItems="center">
              <Text mb={2} fontWeight="medium" as="h2" fontSize={4}>
                Join our talent network
              </Text>
              <NextLink shallow passHref href="/login" legacyBehavior>
                <Button color="customPrimary" mb={1} variant="anchorLink" as="a" className="theme_only">
                  Already a member? Log in here.
                </Button>
              </NextLink>
              <Flex alignItems="center" flexDirection="column" flex="1 1 auto" justifyContent="center">
                <Text fontWeight="medium" fontSize={3} my={3}>
                  {allCompaniesList.length.toLocaleString('en-US')} {organizationsString} use our network as a trusted
                  source to hire top talent.
                </Text>
                <Text mb={3}>Sign up to make yourself searchable to the network.</Text>
                <NextLink shallow passHref href="/onboarding" legacyBehavior>
                  <Button
                    as="a"
                    className="theme_only"
                    onClick={() => {
                      dispatch(toggleSignUpModal({ network }));
                    }}
                    fontSize={3}
                  >
                    Sign up
                  </Button>
                </NextLink>
              </Flex>
              <Box mt={[5, 0]} data-testid="powered-by" width={['120px']}>
                <Box as={Logo} showPoweredBy color={hasAdvancedDesign ? 'var(--color-brand-black-dark)' : undefined} />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </Flex>
  );
};

DetailsLayout.propTypes = {
  network: NetworkSchema.isRequired,
  children: PropTypes.node.isRequired,
  advancedDesign: PropTypes.object,
};

DetailsLayout.defaultProps = {
  advancedDesign: {},
};

export default DetailsLayout;
