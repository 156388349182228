import React from 'react';
import { Flex, Text, Link as StyledLink } from 'rebass/styled-components';
import Link from 'next/link';
import SEO from '../Seo';
import { NetworkSchema } from '../../../schema/network';
import { organizationsStringSelector, talentNetworkSelector } from '../../../redux/selectors/network';

export const FourOFour = ({ network }) => {
  const organizationsString = organizationsStringSelector(true, network);
  const talentNetwork = talentNetworkSelector(network);

  return (
    <>
      <SEO network={network} title="Oops! We can't find that page" />
      <Flex
        data-testid="four-o-four"
        width={[1, 0.3]}
        mx="auto"
        flexDirection="column"
        bg="neutral.20"
        px={[3, 0]}
        my={5}
      >
        <Text as="h1" fontWeight="semibold" fontSize={4} mb={3}>
          Oops! We can&rsquo;t find that page
        </Text>
        <Text fontWeight="body" fontSize={3} mb={3} color="text.main">
          Here are some helpful links:
        </Text>
        <Link href="/jobs" passHref legacyBehavior>
          <StyledLink mb={2} fontWeight="semibold" color="text.main">
            Search jobs
          </StyledLink>
        </Link>
        <Link href="/companies" passHref legacyBehavior>
          <StyledLink mb={2} fontWeight="semibold" color="text.main">
            Explore {organizationsString}
          </StyledLink>
        </Link>

        {talentNetwork && (
          <Link href="/talent-network" passHref legacyBehavior>
            <StyledLink mb={2} fontWeight="semibold" color="text.main">
              Talent network
            </StyledLink>
          </Link>
        )}

        <StyledLink mb={2} fontWeight="semibold" color="text.main" href="mailto:help@getro.com" target="_blank">
          Contact us
        </StyledLink>
      </Flex>
    </>
  );
};

FourOFour.propTypes = {
  network: NetworkSchema,
};

FourOFour.defaultProps = {
  network: null,
};
